import React from "react";
import Layout from "../components/layout";
import { Col, Row } from "reactstrap";
import SEO from "../components/seo";
import loadable from "@loadable/component";
import { isMobile } from "react-device-detect";

const AnimatedCursor = loadable(() => import("../components/AnimatedCursor"));
const About = () => {
  return (
    <Layout>
      {!isMobile && <AnimatedCursor />}
      <SEO title="O mnie" />
      <Row id="about">
        <Col
          sm="6"
          className="d-flex flex-column justify-content-around align-items-center"
        >
          <div className="m-5 w-75">
            <h1>
              Nazywam się
              <br /> Natalia Kalinowska
            </h1>
          </div>
          <div className="m-5 w-75">
            <p style={{fontSize: 24}}>
              Jeśli tutaj trafiłaś lub trafiłeś, to prawdopodobnie szukasz fotografa.
              <br/>
              <br/>
              Pozwól, że się przedstawię – mam na imię Natalia, a moją pasją i pracą jest fotografia. Aparat to dla mnie
              narzędzie, którym ukazuję piękno drugiego człowieka, chwytam ulotne emocje i zapisuję momenty, które stają
              się wspomnieniami na całe życie. Moje zdjęcia trafiają do ramek, na ekrany Waszych smartfonów i do
              rodzinnych albumów, które przekazywane są z pokolenia na pokolenie.
              <br/>
              <br/>
              Specjalizuję się w fotografii ślubnej, weselnej oraz uwiecznianiu uroczystości rodzinnych i innych ważnych
              wydarzeń. W moim domowym studio realizuję sesje rodzinne, noworodkowe, kobiece, a także biznesowe.
              <br/>
              <br/>
              Jako fotograf, staram się nie tylko uchwycić najlepsze kadry, ale też stworzyć przyjazną atmosferę, w
              której każdy czuje się swobodnie. Czasem animuję, czasem podnoszę na duchu, a innym razem po prostu
              przypominam, żeby się wyprostować lub uśmiechnąć. Chętnie podpowiem, jak się ustawić, aby każde zdjęcie
              oddało Wasz naturalny urok.
              <br/>
              Fotografia to wymagająca praca, ale przynosi mi ogromną radość i satysfakcję.

            </p>
          </div>
        </Col>
        <Col
          sm="6"
          className="d-flex justify-content-center align-items-center p-4"
        >
          <img
            alt="Tu będzie zdjęcie ;)"
            src='/images/o_mnie.jpg'
            width="400px"
          />
        </Col>
      </Row>
    </Layout>
  );
};

export default About;
